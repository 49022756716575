html{
    scroll-behavior: smooth;
}

.home-container{
    text-align: center;
}

.home-container .hero-container{
    height: 45em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(107.43% 261.43% at 105.59% 126.46%, var(--primary) 0%, var(--background) 100%);
}

.home-container .hero-content h1{
    font-size: 3rem;
}

.home-container .hero-content .subtitle{
    color: var(--secondary);
    margin: 1em auto;
    width: 60%;
}

.home-container .button-talks{
    background-color: var(--primary);
    padding: 1em;
    width: 13em;
    border-radius: .4em;
    color: var(--font);
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2em;
    display: inline-block;
    margin: 2em 0;
    transition: 2ms;
}

.home-container .button-talks:hover{
    background-color: var(--secondary);
    color: var(--background);
}

.home-container .info-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-container .info-content h3{
    display: flex;
    align-items: center;
}

.home-container .title-container{
    margin: 3em 0;
}

.home-container .talks-list{
    display: grid;
    grid-gap: 2em;
    grid-template-columns: 1fr;
    align-content: center;
    margin: 0 1em;
}

.home-container .talks-container{
    display: grid;
    grid-gap: 2em;
    grid-template-columns: 1fr;
    align-content: center;
    margin-bottom: 6em;
}

.home-container .about-container{
    background: linear-gradient(to right, var(--background), var(--primary));
    height: 25em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-container .about-container h1{
    width: 80%;
    font-weight: normal;
}

.home-container .about-container strong{
    color: var(--secondary);
}

.home-container .speakers-list{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    align-items: center;
}

.home-container .made-by-container{
    margin-bottom: 6em;
}

.home-container .made-by-list{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em;
    align-items: center;
    justify-content: center;
}

.home-container .made-by-list img{
    width: 15em;
}

.home-container .contact{
    margin-top: 5em;
}

@media only screen and (min-width: 960px) {
    .hero-content, .talks-container, .made-by-container {
        margin: 0 15em;
    }

    .home-container .hero-container{
        text-align: left;
        justify-content: start;
    }

    .home-container .hero-content h1{
        font-size: 4rem;
        line-height: .5em;
    }
    
    .home-container .hero-content .subtitle{
        font-size: 1.5rem;
        width: 100%;
        /* overflow: hidden; 
        border-right: .15em solid var(--secondary); 
        white-space: nowrap; 
        letter-spacing: .02em; 
        animation: 
          typing 2s steps(30, end),
          blink-caret .5s step-end infinite; */
        margin-bottom: 2em;
    }

    /* @keyframes typing {
        from { width: 0; }
        to { width: 100% }
    }
    
    @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: var(--secondary);  }
    } */

    .home-container .talk-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 1em 2em;
    }

    .home-container .talk-container .date{
        text-align: center;
        padding: .5em .2em;
        margin: 0;
    }

    .home-container .talk-container .date p{
        margin: 0;
    }

    .home-container .info-content{
        flex-direction: row;
    }

    .home-container .info-content h3{
        width: 36%;
    }

    .home-container .title-container{
        text-align: left;
    }

    .home-container .made-by-list{
        grid-template-columns: 1fr 1fr 1fr;
    }

    .home-container .speakers-list{
        grid-template-columns: 1fr 1fr 1fr;
    }
    
}