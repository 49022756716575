.speaker-container{
    width: 18em;
    height: 15em;
    background-color: var(--card);
    border-radius: 0.4em;
    padding: 2em;
    margin-bottom: 7em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.speaker-container .speaker-avatar{
    width: 10em;
    height: 10em;
    border-radius: .4em;
    position: absolute;
    bottom: 13em;
    object-fit: cover;
}

.speaker-container .speaker-name{
    font-size: 2rem;
    background: linear-gradient(to right, var(--secondary), var(--primary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    margin-top: 3em;
}

.speaker-container .speaker-bio{
    text-align: left;
}